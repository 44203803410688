.footer {
  padding: 3% 15%;
  background-color: black;
  color: white;
}

.footer a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.footer a:hover {
  color: var(--yellow);
}

.footer h3 {
  color: #fff987;
}

.footer-description img {
  width: 150px;
}

.footer-main {
  display: grid;
  grid-template-columns: 40% 25% 25%;
  justify-content: space-between;
}

.footer li {
  list-style-type: none;
}

.footer ul {
  padding: 0px;
}

.footer li {
  line-height: 3;
}

.footer .contact-link {
  display: flex;
  align-items: center;
}

.footer .contact-link i {
  padding-right: 10px;
}

.footer .footer-description p {
  line-height: 2;
}

.rights {
  border-top: 1px solid white;
  text-align: center;
  color: #fff987;
}

.rights p span {
  font-weight: bold;
}

.footer .social-icons {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.footer .social-icons i {
  font-size: 20px;
}

@media screen and (max-width: 650px) {
  .footer {
    padding: 7% 5%;
    text-align: center;
  }

  .footer-main {
    display: grid;
    grid-template-columns: 100%;
  }

  .footer .contact-link {
    justify-content: center;
  }

  .footer .footer-description p {
    line-height: 1.5;
  }

  .footer-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer .social-icons {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 650px) and (max-width: 1000px) {
  .footer {
    padding: 7% 5%;
  }

  .footer-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer .social-icons {
    width: 60%;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .newlevels-header {
    padding-left: 10%;
    padding-right: 10%;
  }

  .footer-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer .social-icons {
    width: 50%;
    margin-bottom: 30px;
  }
}
