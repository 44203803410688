.create_ad {
  text-align: center;
  margin-top: 120px;
  margin-bottom: 20px;
}

.create_ad button {
  width: 30%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.ads-container-form {
  z-index: -1;
}

.ads-container-form::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  height: 100vh;
}

.no-scroll {
  height: auto;
  width: 100vw;
  margin-top: -4%;
  margin-bottom: -3%;
}

.ads-container .ads-form,
.ads-container .confirm-delete {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  border: 1px solid gray;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  z-index: 2;
}

.ads-container .confirm-delete {
  width: max-content;
}

.ads-form h3 {
  text-align: center;
}

.input-fields textarea,
.input-fields select {
  margin-bottom: 15px;
  padding: 15px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid rgb(243, 237, 237);
}

.image-upload {
  position: relative;
}

.hidden {
  display: none;
}

#imageBox {
  background-color: black;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.ads-p-image {
  width: 120px;
  height: 120px;
}

.image-upload {
  width: 120px;
  height: 120px;
  border: 1px solid gray;
}

#uploadedImage {
  max-width: 100%;
  max-height: 100%;
}

#imageBox span {
  display: block;
  padding-top: 40px;
}

#imageBox img {
  display: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-div {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ads-form-btn {
  float: right;
}

.ads-form-btn button {
  padding: 10px 16px;
  margin-left: 10px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  width: 140px;
  height: 40px;
}

.featured-card {
  border: 2px solid var(--yellow);
}

.card-title-x-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-title-x-btn h2 {
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.card-title-x-btn .close-btn {
  text-align: right;
  cursor: pointer;
}

.card-title-x-btn .close-btn i {
  font-size: 25px;
}

@media screen and (max-width: 650px) {
  .create_ad button {
    width: 70%;
  }

  .create_ad {
    margin-top: 100px;
    margin-bottom: 25px;
  }

  .ads-container .ads-form {
    width: 80%;
  }

  .ads-container .confirm-delete {
    width: 80%;
  }
}
