.chatroom {
  padding-left: 15%;
  padding-right: 15%;
  background-color: var(--gray);
  display: grid;
  grid-template-columns: 35% 63%;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}

.chatroom .messages {
  background-color: white;
  border-radius: 15px;
  height: 100%;
}

.messages .message-head {
  padding-left: 15px;
  border-bottom: 1px solid black;
  padding-top: 5px;
}

.chatroom .chat-image {
  width: 50px;
  height: 50px;
}

.chatroom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.message-list .chat {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  border-bottom: 1px solid var(--gray);
  cursor: pointer;
}

.message-list .chat:hover {
  background-color: var(--gray);
}

.chat-details {
  width: calc(100% - 70px);
  margin-left: 10px;
}

.chat-details .chat-header {
  display: flex;
  justify-content: space-between;
}

.chat .chat-details p {
  padding: 0px;
  margin: 3px 0px;
  font-size: 13px;
}

.chat-details .chat-product {
  font-weight: 500;
}

.chatroom .chat-messages {
  background-color: white;
  border-radius: 15px;
  height: 80vh;
  position: relative;
}

.chat-messages .chat-messages-header {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  background-color: black;
  border-radius: 15px;
  color: var(--yellow);
}

.chat-messages-header .chat-details {
  line-height: 0.3;
}

.chat-messages-header .chat-details .chat-product {
  font-weight: 700;
}

.chat-messages-box {
  padding: 10px 15px;
  height: 62vh;
  scrollbar-width: 1px solid;
}

.chat-messages-box .chat-sender {
  margin-top: 10px;
  width: max-content;
  max-width: 500px;
  background-color: var(--yellow);
  padding: 6px 8px 1px 8px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  margin-top: 10px;
}

.chat-sender p {
  margin: 0;
  font-size: 13px;
  margin-bottom: 5px;
}

.chat-sender .time {
  text-align: right;
  font-size: 10px;
  color: gray;
}

.chat-messages-box .chat-receiver {
  margin-top: 10px;
  width: max-content;
  max-width: 500px;
  background-color: var(--gray);
  padding: 6px 8px 1px 8px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
}

.chat-receiver p {
  margin: 0;
  font-size: 13px;
  margin-bottom: 5px;
}

.chat-receiver .time {
  text-align: left;
  font-size: 10px;
  color: gray;
}

.float-right {
  display: flex;
  justify-content: flex-end;
}

.message-input {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 10px;
  left: 10px;
  right: 10px;
  width: 100%;
}

.message-input .chat-send-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.message-input .chat-send-btn i {
  font-size: 20px;
}

.message-input textarea {
  width: calc(100% - 100px);
  height: 30px;
  padding: 10px;
  border: none;
  background-color: var(--gray);
  border-radius: 10px;
}

.chat-messages-header i {
  display: none;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.hide-chat {
  display: block;
}

.show-chat {
  display: block;
}

@media screen and (max-width: 650px) {
  .chatroom {
    padding-left: 3%;
    padding-right: 3%;
    grid-template-columns: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    margin-top: -20px;
  }

  .chatroom .chat-messages {
    display: block;
    height: 80vh;
  }

  .chatroom .messages {
    display: block;
  }

  .chat-messages-header i {
    display: block;
  }

  .chatroom .chat-image {
    width: 40px;
    height: 35px;
  }

  .hide-chat {
    display: none;
  }

  .show-chat {
    display: block;
  }

  .chatroom .messages {
    height: 80vh;
  }

  .chat-details {
    width: 100%;
    margin-left: 10px;
  }

  .chat-messages-box .chat-receiver {
    max-width: 300px;
  }

  .chat-messages-box .chat-sender {
    max-width: 300px;
  }
}

@media screen and (min-width: 651px) and (max-width: 1300px) {
  .chatroom {
    padding-left: 5%;
    padding-right: 5%;
  }
}
