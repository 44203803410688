main {
  margin-bottom: 20px;
  margin-top: 100px;
}

.category-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  padding: 4px 5px;
  background-color: var(--gray);
  border-radius: 5px;
}

.category-list ul li {
  list-style-type: none;
  padding: 5px 15px;
  cursor: pointer;
  color: rgb(63, 60, 60);
  font-weight: 600;
}

.selected-category {
  background-color: #fff987;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-list {
  margin-left: 15%;
  margin-right: 15%;
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18%;
  justify-content: space-between;
}

.product-list a {
  text-decoration: none;
  color: black;
}

.search {
  text-align: center;
  margin-bottom: 1%;
}

.search input {
  width: 40%;
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid black;
}

.similar-product h2 {
  margin-left: 15%;
  margin-right: 15%;
}

@media screen and (max-width: 650px) {
  main {
    margin-top: 60px;
  }

  .category-list ul li {
    padding: 5px 6px;
    font-size: 0.7rem;
  }

  .search input {
    width: 80%;
    padding: 8px;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  .search {
    margin-bottom: 5%;
  }

  .product-list {
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  .similar-product h2 {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 651px) and (max-width: 1300px) {
  main {
    margin-top: 90px;
  }

  .category-list ul li {
    font-size: 0.9rem;
  }

  .search input {
    width: 60%;
  }

  .search {
    margin-bottom: 5%;
  }

  .product-list {
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
  }

  .similar-product h2 {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  main {
    margin-top: 90px;
  }

  .category-list ul li {
    font-size: 0.9rem;
  }

  .product-list {
    margin-left: 10%;
    margin-right: 10%;
    grid-template-columns: 23% 23% 23% 23%;
    justify-content: space-between;
  }

  .similar-product h2 {
    margin-left: 5%;
    margin-right: 5%;
  }
}
