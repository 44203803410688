body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --gray: hsl(210, 40%, 96.1%);
  --yellow: #fff987;
}

.small-size {
  font-size: 11px;
}

.medium-size {
  font-size: 15px;
}

.large-size {
  font-size: 20px;
}

.x-large-size {
  font-size: 30px;
}

button {
  cursor: pointer;
}

footer {
  position: relative;
  z-index: -1000;
}

.response-message {
  border-left: 5px solid black;
  background-color: var(--gray);
  padding: 1px 10px;
  line-height: 0;
  margin-bottom: 20px;
}

.loader {
  border: 4px solid rgb(243, 235, 235);
  border-top: 4px solid gray;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.large-loader {
  border: 10px solid rgb(243, 235, 235);
  border-top: 10px solid gray;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.loading-container {
  width: 100%;
  text-align: center;
}

.product-not-found {
  text-align: center;
  width: 550%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.product-not-found i {
  font-size: 50px;
}

.not-found {
  text-align: center;
  width: 100%;
  margin-top: 200px;
  margin-bottom: 150px;
}

.not-found p {
  font-size: 50px;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

button:disabled {
  background-color: #cccccc; /* Gray background */
  color: #666666; /* Gray text */
  cursor: not-allowed; /* Indicate the button is not clickable */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 650px) {
  .small-size {
    font-size: 10px;
  }

  .medium-size {
    font-size: 14px;
  }

  .large-size {
    font-size: 18px;
  }

  .x-large-size {
    font-size: 20px;
  }

  .product-not-found {
    width: 200%;
  }
}

@media screen and (min-width: 651px) and (max-width: 1300px) {
  .product-not-found {
    width: 300%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .product-not-found {
    width: 400%;
  }
}
