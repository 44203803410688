.newlevels-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15%;
  padding-right: 15%;
  background-color: black;
  color: white;
  top: 0;
  position: fixed;
  right: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.brand-name {
  margin: 5px 0px;
}

.brand-name a {
  text-decoration: none;
  color: #fff987;
}

.brand-name a img {
  width: 160px;
}

.db-profile h4 {
  color: #fff987;
}

.nav-list {
  display: flex;
  align-items: center;
}

.nav-list a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff987;
  text-decoration: none;
  font-weight: bold;
  margin-left: 20px;
  transition: 0.3s ease-in-out;
}

.nav-list a:hover {
  color: white;
}

.nav-list a i {
  margin-right: 10px;
}

.db-profile {
  display: flex;
  align-items: center;
}

.db-profile .header-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mobile-nav {
  display: none;
  position: absolute;
  top: 0;
  background-color: black;
  height: 100vh;
  width: 50vw;
  padding: 0px 20px;
  font-size: 15px;
  align-items: flex-start;
}

.mobile-nav .large-screen-nav {
  display: flex;
}

.mobile-top-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.open-btn {
  display: none;
}

.close-btn i,
.open-btn i {
  font-size: 20px;
}

.close-btn,
.open-btn {
  cursor: pointer;
}

.mobile-nav a {
  margin-left: 0px;
  border-bottom: 1px solid white;
  width: 100%;
  justify-content: flex-start;
}

.hidden-toggle {
  right: -500px;
  animation: slideOut 1s forwards;
}

.show-toggle {
  right: 0;
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  from {
    right: -500px;
  }
  to {
    right: 0;
  }
}

@keyframes slideOut {
  from {
    right: 0;
  }
  to {
    right: -500px;
  }
}

.slide-in {
  animation: slideIn 1s forwards;
}

.slide-out {
  animation: slideOut 1s forwards;
}

@media screen and (max-width: 950px) {
  .newlevels-header {
    padding-left: 5%;
    padding-right: 5%;
  }

  .large-screen-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
  }

  .open-btn {
    display: block;
  }

  .brand-name a img {
    width: 110px;
  }

  .db-profile h4 {
    max-width: 75%;
  }
}

@media screen and (min-width: 951px) and (max-width: 1300px) {
  .newlevels-header {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1600px) {
  .newlevels-header {
    padding-left: 10%;
    padding-right: 10%;
  }
}
