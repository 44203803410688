.product-card {
  border-radius: 10px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  margin-bottom: 15%;
  height: max-content;
}

.product-card .image {
  width: 100%;
  height: 200px;
}

.product-card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.product-card .description {
  line-height: 0;
  padding: 10px 10px;
}

.product-card .description .location,
.product-description .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: gray;
}

.location p {
  margin: 0px;
  line-height: 15px;
}

.product-card .description .location i,
.product-description .location i {
  padding-right: 7px;
  margin: 0px;
}

.product-card button {
  text-align: center;
  width: 100%;
  background-color: black;
  color: #fff987;
  font-size: 1rem;
  font-weight: bold;
  padding: 7px 10px;
  border-radius: 5px;
  border: none;
  transition: 0.3s ease-in-out;
}

.product-card button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.seller-badge {
  display: flex;
  align-items: center;
  line-height: 0;
  margin: -5px 0px -5px 0px;
  padding: 0px;
}

.seller-badge img {
  width: 15px;
  height: 15px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.seller-badge p {
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product-card .description .medium-size-h3 {
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ads-action-btn {
  display: flex;
  justify-content: space-between;
}

.ads-action-btn a {
  width: 48%;
}

.product-card .image {
  position: relative;
  z-index: -2;
}

.image .featured {
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: #fff987;
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.image .featured-container {
  position: absolute;
  top: 0px;
  right: 10px;
}

.image .featured-container p {
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.image .featured i,
.image .featured-container p i,
.price-x-condition .condition i,
.product-page-condition .condition i {
  margin-right: 4px;
}

.featured-card {
  border: 2px solid var(--yellow);
}

.price-x-condition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -5px 0px -5px 0px;
  line-height: 0;
  padding: 0px;
}

.price-x-condition .condition,
.product-page-condition .condition {
  background-color: var(--gray);
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 12px;
}

@media screen and (max-width: 650px) {
  .product-card .image {
    width: 100%;
    height: 150px;
  }

  .product-card button {
    font-size: 0.9rem;
  }

  .image .featured {
    right: 8px;
    background-color: #fff987;
    padding: 6px 9px;
    font-size: 10px;
  }

  .price-x-condition .condition {
    padding: 5px 6px;
    border-radius: 20px;
    font-size: 10px;
  }

  .product-card .description .location {
    font-size: 12px;
  }

  .seller-badge {
    font-size: 12px;
  }
}
