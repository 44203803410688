.form-card {
  display: flex;
  justify-content: center; /* Horizontally center the container */
  align-items: center; /* Vertically center the container */
  height: 100vh;
}

.auth-form {
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-left: 30%;
  margin-right: 30%;
  width: 40%;
}

.input-fields {
  display: flex;
  flex-direction: column;
}

.input-fields input {
  margin-bottom: 15px;
  padding: 15px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid rgb(243, 237, 237);
  width: calc(100% - 35px);
}

.auth-form button {
  text-align: center;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.auth-form button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-actions a {
  text-decoration: none;
  font-size: 0.9rem;
  color: black;
}

.input-password-container {
  position: relative;
}

.icon,
.r-icon {
  color: var(--primary-color);
  position: absolute;
  top: 25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

@media screen and (max-width: 650px) {
  .auth-form {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    padding: 15px;
  }

  .form-actions a {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 651px) and (max-width: 1300px) {
  .auth-form {
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
  }
}
