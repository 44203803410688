.product-page {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

.product-page .product-image {
  width: 50%;
  height: 500px;
}

.product-page .product-description {
  width: 47%;
  line-height: 0;
}

.product-page .product-description .product-name {
  line-height: 1.2;
  margin: 0;
}

.product-page .product-description .product-name p {
  margin: 0;
  margin-bottom: 15px;
}

.product-page img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-description .seller-banner {
  display: flex;
  align-items: center;
  background-color: var(--gray);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.product-description .seller-banner .image-container {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.product-description .seller-banner .image-container img {
  border-radius: 50%;
}

.product-description .ratings {
  display: flex;
  align-items: center;
}

.star {
  color: gray;
  font-size: 20px;
}

.product-description a {
  text-decoration: none;
  color: black;
}

.product-description .product-url {
  color: gray;
  line-height: 1.5;
  margin: 0;
  margin-bottom: -10px;
}

.product-description .product-info {
  line-height: 1.5;
}

.product-page-actions button {
  text-align: center;
  width: 100%;
  background-color: black;
  color: var(--yellow);
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  border: none;
  transition: 0.3s ease-in-out;
}

.product-page-actions button:disabled,
.product-page-actions button:disabled:hover {
  background-color: gray;
  color: white;
  cursor: not-allowed;
  border: none;
}

.product-page-actions button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.product-page-actions input {
  margin: 15px 0px;
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid gray;
  width: calc(100% - 30px);
}

.product-price p {
  font-weight: 600;
}

.product-description .location {
  margin-top: 15px;
  color: gray;
  margin-bottom: -5px;
}

.product-page .product-image {
  position: relative;
}

.product-image .featured {
  position: absolute;
  top: 5px;
  right: 15px;
  background-color: #fff987;
  padding: 7px 10px;
  border-radius: 20px;
  font-size: 15px;
}

.product-image .featured i {
  margin-right: 5px;
}

.product-page-condition .condition {
  width: max-content;
  margin: -5px;
  margin-left: 15px;
  font-size: 15px;
}

@media screen and (max-width: 800px) {
  .product-page {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 90px;
  }

  .product-page .product-image {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }

  .product-page .product-description {
    width: 100%;
  }

  .product-page .product-description .product-name {
    margin-bottom: 30px;
  }

  .product-image .featured {
    top: 15px;
    font-size: 13px;
  }

  .product-page-condition .condition {
    margin: -5px;
    margin-left: 10px;
    font-size: 13px;
  }

  .product-page .product-description .product-name {
    line-height: 1.2;
    margin: 0;
  }

  .star {
    font-size: 15px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1300px) {
  .product-page {
    margin-left: 5%;
    margin-right: 5%;
  }
}
